<template>
  <div class="rightsComment">
    <div class="tab">
      <div
        v-for="(item, index) in tabList"
        :key="index"
        :class="['item', { active: requestData.assessmentType == item.key }]"
        @click="selectMem(item, index)"
      >
        {{ item.label }}
      </div>
    </div>
    <div class="main">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        ref="list"
        :finishedText="finishedText"
      >
        <div
          class="item"
          v-for="(item, index) in listData"
          :key="index"
          @click="toDetail(item, index)"
        >
          <div class="item-l"><img :src="item.picture" alt="" /></div>
          <div class="item-r">
            <div class="name">{{ item.name }}</div>
            <div class="post">{{ item.post }}</div>
          </div>
          <div class="status">
            <img
              v-if="item.assessmentGrade == 3"
              src="./img/basic.png"
              alt=""
            />
            <img
              v-if="item.assessmentGrade == 2"
              src="./img/standard.png"
              alt=""
            />
            <img
              v-if="item.assessmentGrade == 1"
              src="./img/excellent.png"
              alt=""
            />
            <img
              v-if="item.assessmentGrade == 4"
              src="./img/buhege.png"
              alt=""
            />
            <div class="txt">
              {{ commentGradeMap[item.assessmentGrade] }}
            </div>
          </div>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import { gloabalCount } from "@/utils/common";
import wx from "weixin-js-sdk";
import { rightsCommentListUrl } from "./api.js";
import { commentGrade, commentGradeMap } from "./map.js";

import { getHashParam } from "@/utils/utils.js";
var moment = require("moment");
export default {
  name: "partyMember",
  data() {
    return {
      commentGradeMap,
      tabList: [
        { label: "锋领考核", key: "1" },
        { label: "民主评议", key: "2" },
      ],
      finishedText: "没有更多了",
      finished: false,
      requestData: {
        curPage: 1,
        pageSize: 10,
        assessmentType: 1,
      },
      listData: [],
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  mounted() {
    console.log(commentGradeMap, 990);
    gloabalCount("", 53, 1);
  },
  methods: {
    toAlink(item) {
      window.location.href = `tel://${item.mobile}`;
    },
    toDetail(item) {
      //   let params = {
      //     id: item.id,
      //   };
      //   if (wx.miniProgram) {
      //     wx.miniProgram.navigateTo({
      //       url: `/xubPages/partyInfoDetail/index?params=${JSON.stringify(
      //         params
      //       )}`,
      //     });
      //   }
    },
    selectMem(item, index) {
      this.requestData.assessmentType = item.key;
      this.finished = false;
      this.finishedText = "没有更多了";
      this.$refs.list._data.loading = true;
      this.requestData.curPage = 1;
      this.listData = [];
      this.getPartyList();
    },
    onLoad() {
      this.getPartyList();
    },
    async getPartyList(topicTypes) {
      let params = {
        tenantId: this.tenantId,
        assessmentType: this.requestData.assessmentType,
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
      };
      let res = await this.$axios.get(`${rightsCommentListUrl}`, {
        params: params,
      });
      console.log(res, 333);
      if (res.code === 200) {
        if (res.data.pages <= res.data.current) {
          this.finished = true;
        } else {
          this.$refs.list._data.loading = false;
        }
        this.finishedText = "没有更多了";
        if (res.data.records.length === 0) {
          this.finishedText = "";
          return;
        }
        res.data.records.forEach((item) => {
          this.listData.push(item);
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.rightsComment {
  min-height: 100vh;
}
.rightsComment {
  padding-top: 120px;
  .tab {
    display: flex;
    justify-content: space-between;
    background: #fff;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: border-box;
    z-index: 999;
    .item {
      flex: 1;
      text-align: center;
      padding: 28px 0;
      font-size: 28px;
      font-weight: 400;
      color: #323334;
      line-height: 40px;
    }
    .active {
      position: relative;
      font-weight: 700;
      &::after {
        content: "";
        position: absolute;
        width: 64px;
        height: 8px;
        background: #007eff;
        border-radius: 4px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
  .main {
    padding: 0 32px;
    .item {
      padding: 20px 26px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      height: 156px;
      background: #ffffff;
      box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
      border-radius: 16px;
      margin-bottom: 30px;
      .item-l {
        width: 114px;
        height: 114px;
        margin-right: 16px;
        border-radius: 16px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 16px;
        }
      }
      .item-r {
        flex: 1;
        .name {
          font-size: 32px;
          font-weight: 600;
          color: #323334;
          line-height: 44px;
          margin-bottom: 14px;
        }
        .post {
          font-size: 28px;
          font-weight: 400;
          color: #ff853a;
          line-height: 40px;
        }
      }
      .status {
        width: 166px;
        height: 55px;
        position: absolute;
        top: 18px;
        right: 28px;
        img {
          width: 100%;
          height: 100%;
        }
        .txt {
          top: 0;
          left: 0;
          position: absolute;
          z-index: 99;
          width: 100%;
          height: 100%;
          text-align: center;
          margin-left: 24px;
          color: #fff;
          font-size: 20px;
          font-weight: 600;
          line-height: 48px;
        }
      }
    }
  }
}
</style>
